<template>
  <div>
    <div class="my-8 drivers">
    <h2 class="main-color">{{ $t("Manage Coordinators") }}</h2>
    <v-data-table
      :options.sync="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      item-key="id"
      :show-select="false"
      class="elevation-1 level1 templates text-center"
      text="left"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-btn @click="openAssignDialog(item)" icon>
          <v-icon :title="$t('Assign Teachers')">supervised_user_circle</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <div class="text-center my-2">
      <v-pagination
        v-if="length > 0"
        v-model="page"
        :length="length"
        :total-visible="totalVisible"
        circle
      ></v-pagination>
    </div>

    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h5 main-color">
          {{ $t("Select Teachers") }}
        </v-card-title>
        <v-card-text class="my-4">
          <v-form v-model="valid" ref="form">
            <v-autocomplete
              v-model="selectedTeachers"
              :items="teachers"
              solo
              small-chips
              :label="$t('Teachers')"
              item-text="name"
              item-value="id"
              multiple
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="modal-btn-cancel" @click="closeDialog()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn class="modal-btn-save" @click="assignTeachers()">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
  </div>

   <v-snackbar
      v-model="snack"
      :timeout="snackTime"
      :color="snackColor"
      :right="true"
      :top="true"
    >
      <!-- {{ snackText }} -->

      <span class="white--text">
        {{ snackText }}
      </span>

      <v-btn text @click="snack = false">{{ $t("Close") }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>

import axios from "axios";

export default {
  name: "manage_coordinators",
  data() {
    return {
      options: {
        itemsPerPage: 15,
      },
      snack: "",
    //   types: [],
      dialog: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      formTitle: "",
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Name"),
          value: "name",
          sortable: false,
        },
        {
          text: this.$i18n.t("Teachers"),
          value: "teachers",
          sortable: false,
        },
        

        { text: this.$i18n.t("Actions"), value: "actions", sortable: false },
      ],
      title: this.$i18n.t("Manage Coordinators"),
    
      editedItem: {
       
      },
      permissions: {},
      selectedAcoordinator:"",
      selectedTeachers:[],
      teachers:[],
      items: [],
      valid:true,
      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      editedIndex: -1,
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getAllData(this.$route.query.page);
        } else {
          this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function() {
      this.$router.push(
        {
          path: "/manage-Coordinators?page=" + this.page,
        },
        () => {}
      );
    },

  },
  methods: {
   
   openAssignDialog(item){
       this.dialog =true;
       this.selectedAcoordinator = item.id;
       this.selectedTeachers = item.teachers_ids;
    //    console.log(item);
   },
   closeDialog(){
       this.dialog =false;
       this.selectedAcoordinator ='';
       this.selectedTeachers = [];
   },
   assignTeachers(){
    if (this.valid) {
        this.$http
          .post(
            this.getApiUrl + "/users/assignTeachersToCoordinator",

            { id: this.selectedAcoordinator, teachers_ids: this.selectedTeachers },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                //the token is a variable which holds the token
              },
            }
          )
          .then((response) => {
            this.closeDialog(true);
            console.log(response.data.status.message);
           
            this.snack = true;
            this.snackColor = "success";
            this.snackText = response.data.status.message;
             this.getAllData(1);
          });
      } else {
        this.validate();
      }
   },
   getTeachers(){
       //getAllTeachers
       axios
        .get(
          this.getApiUrl + "/users/getAllTeachers",
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.teachers = [];
          this.teachers = response.data.data;
         
        });
    },
    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl + "/users/manageCoordinators?page=" + page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },
    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },
  },
  mounted() {
    // this.getTypes();
    if (this.$route.query.page !== undefined) {
      this.page = Number(this.$route.query.page);
      this.getAllData(this.page);
    } else {
      this.page = 1;
      this.getAllData(this.page);
    }
    this.getTeachers();
  },
};
</script>